import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Splash from '../../assets/landing_background.png';
import incubatorLogo from '../../assets/Incubator_logo.svg';
import { setState, login } from "../../redux/actions/loginActions";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

//******************************************/

const Page = styled.div` 
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    `

const Workspace = styled.div`    
    top: 0;
    bottom: 0;
    left: 0;
    right: 420px;
    position: fixed;
    `

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 20%;
    bottom: 0;
    right: 0;
    width: 420px;
    min-width: 420px;
    `

const Logo = styled.div`
    padding-bottom: 100px;
    `

const LogoLine1 = styled.div`
    display: flex;
    align-items: center; 
    font-weight: 700;
    font-size: 46px;
    color: #323334;
    `

const Egg = styled.img`
    width: 43px;
    height: 54px;
    margin-right: 10px;
    align-items: center;
`

const LogoLine2 = styled.div`
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    font-weight: 400;
    font-size: 46px;
    margin-top: -6px;
    color: #323334;
    `

const SwapPanels = styled.div`
    position: relative;
`

//******************************************/

const useStyles = makeStyles({
    splash: {
        backgroundImage: `url(${Splash})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },

    textfield: {
        margin: '10px 0'
    },
    text: {
        margin: '10px 0',
        fontSize: '14px'
    },
    button: {
        cursor: 'pointer',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 80px',
        gap: '10px',
        position: 'relative',
        width: '267px',
        height: '55px',
        border: '1.5px solid #002677',
        backgroundColor: 'white',
        color: '#002677',
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '700',
        fontFamily: 'Optum Sans,sans-serif,uhc_sans',

        "&:hover": {
            backgroundColor: '#002677',
            color: 'white',
        }
    },
    link: {
        color: 'var(--link-color)',
        fontWeight: 500,
        cursor: 'pointer'
    },
    check: {
        position: 'relative',
        top: 3,
        width: '16px',
        height: '16px',
        marginRight: '5px',
        color: '#C0C0C0'
    },
    saving: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '80px'
    }
});

const Authentication = ({ state, setState }) => {
    const [loginState, setLoginState] = useState('DONE');

    const classes = useStyles();
    const { instance } = useMsal();
    const { t } = useTranslation();

    function handleLogin() {
        setLoginState('AUTHENTICATING');

        instance.ssoSilent({
            redirectUri: '/editor',
            scopes: [`api://${process.env.REACT_APP_REGISTRATIONAPPID}/access_as_user`]

        })
        .then(()=>{
            setLoginState('DONE');
        })
        .catch(e => {
            instance.loginRedirect({
                redirectUri: '/editor',
                scopes: [`api://${process.env.REACT_APP_REGISTRATIONAPPID}/access_as_user`],
                prompt: 'select_account'
            }).then(() => {
                setLoginState('DONE');
            })
            .catch(()=>{
                setLoginState('ERROR');                
            })
        })
    }

    return (
        <Page>
            <Workspace className={classes.splash}>
            </Workspace>
            <Sidebar>

                <Logo>
                    <LogoLine1>
                        <Egg src={incubatorLogo} alt='incubator logo' />
                        {t("login.incubator")}
                    </LogoLine1>
                    <LogoLine2>{t("login.editor")}</LogoLine2>
                </Logo>

                <SwapPanels>
                    <div>
                        {
                            loginState === 'AUTHENTICATING'
                                ? <CircularProgress />
                                :
                                <button
                                    variant="outlined"
                                    className={classes.button}
                                    onClick={() => handleLogin()}
                                >
                                    Log In
                            </button>
                        }
                    </div>
                </SwapPanels>
            </Sidebar>
        </Page >
    )
}

const mapStateToProps = (state) => {
    state = state.loginReducer;

    return {
        state
    };
};

const mapDispatchToProps = {
    setState,
    login
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);