import React, { useState, useEffect } from "react";
import Toolbar from '../sn-editor/sidebar/widgets/Toolbar';
import styled from 'styled-components';
import RefSVG  from '../../assets/Support/RefSVG.svg';
import FAQSVG  from '../../assets/Support/FAQSVG.svg';
import ReleaseSVG  from '../../assets/Support/ReleaseSVG.svg';
import ContactSVG  from '../../assets/Support/ContactSVG.svg';
import ChangeLogSVG  from '../../assets/Support/ChangeLogSVG.svg';
import SideBar from "./SideBar";
import WorkSpace from './WorkSpace';
import { useTranslation } from "react-i18next";
import { saveConfigs, setState, loadConfigs } from '../../redux/actions/editorActions';
import { connect } from "react-redux";
import { getProfileData } from '../../redux/actions/loginActions';
import { loadAdminData } from '../../redux/actions/adminActions';

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 1300px;
    overflow-x: auto;    
`

const HelpBack = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 232px;
    background: #FBF9F4;
`

const Help = styled.div`
    height: 40px;
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
`

const Tools = styled.div`
    display: flex;
    margin: auto;
    padding: 80px 0px 0px 0px;
    cursor: pointer;
    gap: 64px;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
`

const Links = styled.div`
    width: 202px;
`

const Image =styled.img`
    padding-bottom: 16px;
    margin-left: 63px;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #0C55B8;
    padding-bottom: 16px;
`

const Markdown = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
`

const SupportPage = styled.div`
    display: flex;
    position: absolute;
    top: 60px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    min-width: 1300px;
    overflow-x: auto;
`

//*****************************************/

const Support = ({ appId, editorState, saveConfigs, login, lastappId, getProfileData, loadAdminData, loadConfigs }) => {

    const [showRef, setShowRef]  = useState(false);
    const [showFAQ, setShowFAQ]  = useState(false);
    const [showChange, setShowChange]  = useState(false);
    const [showRelease, setShowRelease]  = useState(false);
    const [showContact, setShowContact]  = useState(false);
    const [showSupportCenter, setShowSupportCenter]  = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if(login && login.account && appId === ''){
            getProfileData(login.account && login.account.username);
            loadAdminData(login.account && login.account.username);
            setState('username', login.account.username);
        }
        if(appId !== lastappId){
            loadConfigs(appId);
            setState('appIdState','DONE');
            setState('lastappId', appId);
        }
    }, [appId, login]);

    return (
        <Page>
            <Toolbar save={() => saveConfigs(editorState, appId)} />
            {
                !showSupportCenter ?
                    <>
                        <HelpBack>
                            <Help>{t("Support.support.Help")}</Help>
                        </HelpBack>

                        <Tools>
                            <Links onClick={()=>{ setShowRef(true); setShowSupportCenter(true) } }>
                                <Image src={RefSVG} />
                                <Heading>{t("Support.support.Heading1")}</Heading>
                                <Markdown>{t("Support.support.mark1")}</Markdown>
                            </Links>

                            <Links onClick={()=> { setShowFAQ(true); setShowSupportCenter(true) } }>
                                <Image src={FAQSVG} />
                                <Heading>{t("Support.support.Heading2")}</Heading>
                                <Markdown>{t("Support.support.mark2")}</Markdown>
                            </Links> 

                            <Links onClick={()=> {setShowChange(true); setShowSupportCenter(true) } }>
                                <Image src={ChangeLogSVG} />
                                <Heading>{t("Support.support.Heading3")}</Heading>
                                <Markdown>{t("Support.support.mark3")}</Markdown>
                            </Links> 

                            <Links onClick={ ()=> {setShowRelease(true); setShowSupportCenter(true) } }>
                                <Image src={ReleaseSVG} />
                                <Heading>{t("Support.support.Heading4")}</Heading>
                                <Markdown>{t("Support.support.mark4")}</Markdown>
                            </Links> 

                            <Links style={{marginRight: '0px'}} onClick={()=> {setShowContact(true); setShowSupportCenter(true)} } >
                                <Image src={ContactSVG} />
                                <Heading>{t("Support.support.Heading5")}</Heading>
                                <Markdown>{t("Support.support.mark5")}</Markdown>
                            </Links> 
                        </Tools>
                    </>
                :
                    <SupportPage>
                        <SideBar showRef={showRef} setShowRef={setShowRef} showFAQ={showFAQ} setShowFAQ={setShowFAQ} showChange={showChange} setShowChange={setShowChange} showRelease={showRelease} setShowRelease={setShowRelease} showContact={showContact} setShowContact={setShowContact} setShowSupportCenter={setShowSupportCenter} />
                        <WorkSpace showRef={showRef} setShowRef={setShowRef} showFAQ={showFAQ} showChange={showChange} showRelease={showRelease} showContact={showContact} setShowContact={setShowContact} setShowFAQ={setShowFAQ} />
                    </SupportPage>
            }
        </Page>
    )
}

const mapStateToProps =(state)=>{
    const editorState = state.editorReducer;
    const appId = state.loginReducer.appIdValue;
    const lastappId = state.editorReducer.lastappId;

    return {
        editorState, 
        appId,
        lastappId
    }
}

export default connect(mapStateToProps, { setState, loadConfigs, loadAdminData, getProfileData, saveConfigs })(Support);