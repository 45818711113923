export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_REGISTRATIONAPPID,
      authority: "https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: '/editor',
      postLogoutRedirectUri : '/'
    }
    // cache: {
    //   cacheLocation: "localStorage", // This configures where your cache will be stored
    //   storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    // }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: [`api://${process.env.REACT_APP_REGISTRATIONAPPID}/access_as_user`]
  };

  export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/User.Read'
};