import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Authentication from './components/login/LoginPage';
import WelcomeScreen from './components/welcome/WelcomePage';
import ProductSelect from './components/product-select/ProductSelect';
import Dashboard from './components/reporting/Dashboard';
import Editor from './components/sn-editor/EditorPage';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import Support from "./components/support/Support";


const App = () => {
    const { result } = useMsalAuthentication(InteractionType.Silent, {
        scopes: [`api://${process.env.REACT_APP_REGISTRATIONAPPID}/access_as_user`]
    });

    return (
        <BrowserRouter>
            <AuthenticatedTemplate>
                <Routes>
                    <Route exact path="/" element={<Navigate to="/editor" replace />} />
                    <Route exact path="/welcome" element={<WelcomeScreen />} />
                    <Route exact path="/product-select" element={<ProductSelect />} />
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route exact path="/reporting" element={<Dashboard />} />
                    <Route exact path="/editor" element={<Editor login={result} />} />
                    <Route exact path="/support" element={ <Support login={result} /> } />
                </Routes>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Routes>
                    <Route exact path="/" element={<Authentication />} />
                    <Route exact path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </UnauthenticatedTemplate>
        </BrowserRouter>
    )
}

export default App;